import Col, { Row } from '@sportnet/ui/Grid';
import Icon from '@sportnet/ui/Icon';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalContent } from '@sportnet/ui/Modal';
import { mb, mediaSizes } from '@sportnet/ui/Themes/utilities';
import {
  endOfMonth,
  format,
  getYear,
  isSameDay,
  isSameMonth,
  isSameYear,
  setDate,
  setMonth,
  setYear,
  startOfMonth,
} from 'date-fns';
import { rem } from 'polished';
import { QueryHocTypes } from '@sportnet/query-hoc';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import styled, { css, keyframes } from 'styled-components';
import Api from '../../api/Api';
import EntryAnimation from '../../components/EntryAnimation';
import EventPreview from '../../components/EventPreview';
import ChevronLeftIcon from '../../components/Icons/chevronLeft';
import ChevronRightIcon from '../../components/Icons/chevronRight';
import ChildIcon from '../../components/Icons/child';
import SchoolIcon from '../../components/Icons/school';
import MaxWidthBox from '../../components/MaxWidthBox';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import Page from '../../containers/Page';
import { RouteProps } from '../../library/App';
import fluidTypography from '../../utilities/fluidTypography';
import __ from '../../utilities/__';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import RedirectToRegistration from './redirectToRegistration';
import { listEventsSelector } from './selectors';
import { StatCount, StatDesc } from '../Schools/list';

const EVENTS_LIST_LIMIT = 48;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  :before {
    pointer-events: none;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 244, 214, 0.75) 0%,
      rgba(255, 244, 214, 0.25) 25%,
      rgba(255, 244, 214, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 244, 214, 0.75) 0%,
      rgba(255, 244, 214, 0.25) 25%,
      rgba(255, 244, 214, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 244, 214, 0.75) 0%,
      rgba(255, 244, 214, 0.25) 25%,
      rgba(255, 244, 214, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
`;

const Ad = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.primary};
  border-radius: ${rem(12)};
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(24)};
  ${mb('l')} {
    flex-direction: row;
    padding: ${rem(48)};
    height: ${rem(200)};
    margin-bottom: ${rem(52)};
  }
  > a {
    width: 100%;
    ${mb('l')} {
      width: auto;
    }
  }
`;

const AdInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  ${mb('l')} {
    flex-direction: row;
    > *:first-child {
      padding-right: ${rem(48)};
    }
  }
`;

const AdImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: -${rem(48)};
  ${mb('l')} {
    margin-top: -${rem(16)};
    height: ${rem(200)};
    width: auto;
  }
`;

const AdContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)} 0;
  text-align: center;
  ${mb('l')} {
    padding: 0;
    text-align: left;
  }
`;

const AdHeader = styled.div`
  color: #ffffff;
  opacity: 1;
  ${fluidTypography(12, 18, mediaSizes.l, 1920)};
`;

const AdText = styled.div`
  ${fluidTypography(24, 32, mediaSizes.l, 1920)};
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  padding-top: ${rem(16)};
`;

const AdCTA = (styled as any).button`
  cursor: pointer;
  color: ${({ theme }: any) => theme.color.primary};
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 ${rem(2)} 0 rgba(0, 0, 0, 0.04);
  border: ${rem(2)} solid #ffffff;
  border-radius: ${rem(12)};
  padding: 0 ${rem(24)};
  height: ${rem(56)};
  font-weight: bold;
  width: 100%;
  ${mb('l')} {
    width: auto;
    height: ${rem(72)};
  }
  ${fluidTypography(14, 20, mediaSizes.l, 1920)};
  position: relative;
  overflow: hidden;
  position: relative;
  span {
    z-index: 2;
    position: relative;
  }
  :before {
    content: '';
    transition: all 0.3s;
    width: 1px;
    height: 1px;
    z-index: 1;
    border-radius: 100%;
    background-color: #fafafa;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  :hover {
    :before {
      transform: translate(-50%, -50%) scale(350);
    }
  }
`;

const IntroWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${rem(4)};
`;

const MapHeader = styled.div`
  text-align: center;
  h1 {
    margin-bottom: 0;
    margin-top: ${rem(48)};
    ${mb('l')} {
      margin-bottom: ${rem(48)};
    }
    ${fluidTypography(30, 48, mediaSizes.l, 1920)};
  }
`;

const ActivitiesHeader = styled.div`
  text-align: center;
  > div {
    padding-bottom: ${rem(20)};
    ${mb('m')} {
      padding-bottom: ${rem(40)};
    }
  }
  > div > * {
    ${fluidTypography(14, 16, mediaSizes.l, 1920)};
  }
  h1 {
    ${fluidTypography(30, 48, mediaSizes.l, 1920)};
    margin-bottom: ${rem(16)};
    margin-top: ${rem(48)};
    ${mb('l')} {
      margin-top: ${rem(80)};
    }
  }
  a {
    font-weight: bold;
    color: #000;
    text-decoration: underline;
  }
`;

const Header = styled.h1`
  ${fluidTypography(40, 60, mediaSizes.l, 1920)};
  font-weight: bold;
  margin: ${rem(24)} 0;
`;

const Banner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(48)} 0;
  ${mb('l')} {
    flex-direction: row;
    padding: ${rem(80)} 0;
  }

  p {
    ${fluidTypography(12, 18, mediaSizes.l, 1920)};
    margin: ${rem(8)} 0;
    ${mb('l')} {
      margin: ${rem(16)} 0;
    }
  }
  > * {
    width: 50%;
  }
  > img {
    max-width: ${rem(600)};
    width: 100%;
  }
  > div {
    ${mb('l')} {
      padding-left: ${rem(48)};
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Intro = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;
  ${mb('l')} {
    flex-direction: row;
  }
  > ${Banner} {
    width: 100%;
    display: flex;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(16)};
`;

const StyledCol = styled(Col)`
  padding: 0;
`;

const Calendar = styled.div`
  margin: ${rem(24)} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  ${mb('l')} {
    flex-direction: row-reverse;
  }
  width: 100%;
`;

const YearWrapper = styled.div`
  height: ${rem(40)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(24)};
  width: 100%;
  ${mb('l')} {
    width: ${rem(320)};
    max-width: ${rem(320)};
    margin-bottom: 0;
  }
  padding: 0 ${rem(4)};
  background: #fff;
  box-shadow: 0 ${rem(2)} 0 rgba(0, 0, 0, 0.04);
  border-radius: ${rem(28)};
`;

const Year = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? '#C2C2C2' : '#000')};
  font-weight: bold;
  display: flex;
  align-items: center;
  > span:first-child {
    padding-right: ${rem(16)};
  }
  padding: ${rem(4)};
  margin: 0 ${rem(20)};
  ${fluidTypography(14, 18, mediaSizes.l, 1920)};
`;

const Months = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  position: relative;
  height: ${rem(48)};
  align-items: center;
  padding-left: ${rem(12)};
  width: calc(100% + ${rem(32)});
  ${mb('l')} {
    padding-left: 0;
    height: auto;
    overflow: unset;
    width: 100%;
  }
  > div:last-child {
    margin-right: ${rem(18)};
    ${mb('l')} {
      margin-right: 0;
    }
  }
`;
const Month = styled.div<{ active?: boolean; disabled?: boolean }>`
  background: ${({ active }) => (active ? '#FFD150' : '#fff')};
  color: ${({ disabled }) => (disabled ? '#C2C2C2' : '#000')};
  > div:first-child {
    font-weight: bold;
    ${fluidTypography(14, 18, mediaSizes.l, 1920)};
  }
  max-width: ${rem(78)};
  height: ${rem(32)};
  width: calc(${100 / 12}% - ${rem(8)});
  min-width: ${rem(57)};
  ${mb('l')} {
    height: ${rem(40)};
    min-width: auto;
  }
  box-shadow: 0 ${rem(2)} 0 rgba(0, 0, 0, 0.04);
  border-radius: ${rem(28)};
  margin: ${rem(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
`;

const EventCount = (styled as any).div`
  position: absolute;
  top: -${rem(8)};
    right: 0;
  z-index: 1;
  ${fluidTypography(8, 11, mediaSizes.l, 1920)};
  padding: ${rem(2)} ${rem(8)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }: any) => theme.color.primary};
  color: white;
  border-radius: ${rem(16)};
`;

const EventWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    background: #eee;
  }
`;

const Events = styled.div`
  padding-top: ${rem(24)};
  padding-bottom: ${rem(8)};
  ${EventWrapper} {
    padding: ${rem(8)};
    border-bottom: ${rem(1)} solid #eee;
    :last-child {
      border-width: 0;
    }
  }
`;
const EventName = styled.div`
  font-size: ${rem(14)};
`;
const EventSportGround = styled.div`
  font-weight: bold;
  padding: ${rem(8)};
`;
const EventDuration = styled.div`
  color: #777;
`;

const BannerContent = styled.div`
  width: 100%;
  text-align: center;
  ${mb('l')} {
    text-align: left;
  }
`;

const BannerDescription = styled.div`
  a {
    color: #000 !important;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const BannerActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${rem(16)} 0;
  ${mb('l')} {
    flex-direction: row;
    padding: ${rem(40)} 0;
  }
  > * {
    width: 100%;
    margin-top: ${rem(8)};
    margin-bottom: ${rem(8)};
    ${mb('l')} {
      width: calc(50% - ${rem(8)});
      margin-right: ${rem(16)};
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  button {
    overflow: hidden;
    position: relative;
    span {
      z-index: 2;
    }
    justify-content: center;
    color: #000;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    > span:first-child {
      padding-right: ${rem(12)};
    }
    padding: 0 ${rem(18)};
    height: ${rem(56)};
    ${fluidTypography(14, 20, mediaSizes.l, 1920)};
    font-weight: bold;
    ${mb('l')} {
      padding: 0 ${rem(24)};
      height: ${rem(72)};
    }
  }
`;

const RegisterSchool = styled.button`
  width: 100%;
  background: #ffd150;
  border: 2px solid #ffd150;
  :before {
    content: '';
    transition: all 0.3s;
    width: 1px;
    height: 1px;
    z-index: 1;
    border-radius: 100%;
    background-color: rgb(247 201 74);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  :hover {
    :before {
      transform: translate(-50%, -50%) scale(350);
    }
  }
`;

const RegisterChild = styled.button`
  background: #fff;
  border: 2px solid #ffffff;
  :before {
    content: '';
    transition: all 0.3s;
    width: 1px;
    height: 1px;
    z-index: 1;
    border-radius: 100%;
    background-color: #fafafa;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  :hover {
    :before {
      transform: translate(-50%, -50%) scale(350);
    }
  }
`;

const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: ${rem(16)};
  padding: 0 0 ${rem(16)} 0;
  ${mb('xs')} {
    flex-direction: row;
    padding: 0 0 ${rem(40)} 0;
  }
  > * {
    flex: 1;
    flex-basis: 0;
  }
`;

const blink = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  margin: 0 4px;
  animation: ${blink} 1s infinite;
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Stat = styled.div<{ $loading?: Boolean }>`
  width: 100%;
  background: #ffd150;
  border: 2px solid #ffd150;
  border-radius: 12px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  padding: ${rem(16)};
  color: #000;
  > * {
    text-align: center;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${rem(24)} 0;
  ${mb('l')} {
    padding: ${rem(32)} 0;
  }
`;

const StyledLoader = styled(Loader)<{ loading: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.2s, opacity 0.2s;
  transform-origin: left top;
  ${({ loading }) => {
    if (loading) {
      return css`
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
      `;
    }
    return css`
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    `;
  }};
`;

const LoadMoreButton = styled.button`
  position: relative;
  width: 100%;
  justify-content: center;
  color: #000;
  box-shadow: 0px 2px 0px rgba(236, 190, 74, 1);
  border: 2px solid #ffd150;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 ${rem(18)};
  height: ${rem(56)};
  ${fluidTypography(14, 20, mediaSizes.l, 1920)};
  font-weight: bold;
  ${mb('l')} {
    padding: 0 ${rem(24)};
    width: ${rem(320)};
    height: ${rem(72)};
  }
  background: #ffd150;
  overflow: hidden;
  position: relative;
  div {
    z-index: 2;
  }
  :before {
    content: '';
    transition: all 0.3s;
    width: 1px;
    height: 1px;
    z-index: 1;
    border-radius: 100%;
    background-color: rgb(247 201 74);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  :hover {
    :before {
      transform: translate(-50%, -50%) scale(350);
    }
  }
`;

const LoadMoreContent = styled('div')<{ $isLoading: boolean }>`
  transition: opacity 0.2s;
  opacity: ${({ $isLoading }) => ($isLoading ? 0.25 : 1)};
  display: flex;
  align-items: center;
`;

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({
  events: listEventsSelector(state),
  total: getListTotal(config.HOME_EVENTS_LIST_NAME)(state),
  isFetching: isCommiting(config.HOME_EVENTS_LIST_NAME)(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateEvents: updateEntities,
};

type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = RouteProps<{}> &
  OwnProps &
  IMapStateToProps &
  ReduxConnectProps &
  IMapDispatchToProps;

const title = __('Hlavná stránka');

const months = {
  Jan: '',
  Feb: '',
  Mar: '',
  Apr: '',
  Máj: '',
  Jún: '',
  Júl: '',
  Aug: '',
  Sep: '',
  Okt: '',
  Nov: '',
  Dec: '',
};

const Home: React.FC<Props> = ({
  setList,
  commitList,
  updateEvents,
  events,
  isFetching,
  location: { search, pathname },
  router,
  total,
}) => {
  const [activeDate, setActiveDate] = React.useState(new Date());
  const [eventsCount, setEventsCount] = React.useState<{
    [key: string]: number;
  }>({});
  const [displayedYear, setDisplayedYear] = React.useState(
    format(new Date(), 'yyyy'),
  );

  const [modalOpened, setModalOpened] = React.useState(false);

  const [Leaflet, setLeaflet] = React.useState<any>(null);

  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    if (window) {
      const reactLeaflet = require('react-leaflet');
      const leaflet = require('leaflet');

      setLeaflet({
        Map: reactLeaflet.Map,
        Marker: reactLeaflet.Marker,
        TileLayer: reactLeaflet.TileLayer,
        Popup: reactLeaflet.Popup,
        FeatureGroup: leaflet.FeatureGroup,
        LeafletMarker: leaflet.Marker,
      });
    }
  }, []);

  const [statsIsLoading, setStatsIsLoading] = React.useState('');
  const [seasons, setSeasons] = React.useState<
    Array<{ _id: string; name: string }>
  >([]);
  React.useEffect(() => {
    setStatsIsLoading((s) => s + 'S');
    Api.getPublicSeasons(config.PROJECT_NAME)
      .then((response) => {
        setSeasons(response.items);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setStatsIsLoading((s) => s.replace('S', ''));
      });
  }, []);

  const [organizations, setOrganizations] = React.useState<any[]>([]);
  const [eventsStats, setEventsStats] = React.useState(0);

  React.useEffect(() => {
    if (seasons && seasons.length) {
      const seasonId = seasons[0]._id;
      // loadneme organizacie
      setStatsIsLoading((s) => s + 'O');
      Api.getPublicOrgranizations(config.PROJECT_NAME, { seasonId })
        .catch((e) => {
          console.error(e);
        })
        .then((r: any) => {
          setOrganizations(r.items);
        })
        .finally(() => {
          setStatsIsLoading((s) => s.replace('O', ''));
        });
      // loadneme aktivity
      setStatsIsLoading((s) => s + 'E');
      Api.getEventsCountBySeasonId(config.PROJECT_NAME, seasonId)
        .catch((e) => {
          console.error(e);
        })
        .then((r: any) => {
          setEventsStats(
            (Object.values(r.byAppSpace) as number[]).reduce((rr, r) => rr + r),
          );
        })
        .finally(() => {
          setStatsIsLoading((s) => s.replace('E', ''));
        });
    }
  }, [seasons]);

  const { query, setQuery: setParameter } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        eventsDate: StringParam(''),
        selectedMarker: StringParam(''),
      },
    },
  );

  const normalizeEvents = (data: any[]) => {
    return {
      entities: {
        events: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const getEventsCount = React.useCallback(async () => {
    try {
      // setIsFetchingEventCount(true);
      const res = await Api.getEventsCountByDate(config.PROJECT_NAME);
      setEventsCount(
        res.eventsByDate.reduce((acc, i) => ({ ...acc, [i._id]: i.count }), {}),
      );
    } catch (e: any) {
      //
    } finally {
      // setIsFetchingEventCount(false);
    }
  }, []);

  React.useEffect(() => {
    getEventsCount();
  }, [getEventsCount]);

  React.useEffect(() => {
    if (query.eventsDate) {
      setDisplayedYear(String(getYear(new Date(query.eventsDate as string))));
      setActiveDate(new Date(query.eventsDate as string));
    }
  }, [query.eventsDate]);

  const toggleModal = () => setModalOpened(!modalOpened);

  const fetchEvents = async (items = []) => {
    setList({
      listName: config.HOME_EVENTS_LIST_NAME,
      params: {
        activeDate: format(activeDate, config.OUTPUT_DATE_FORMAT),
        offset,
      },
    });
    await commitList({
      listName: config.HOME_EVENTS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.publicGetEvents(config.PROJECT_NAME, {
            offset,
            limit: EVENTS_LIST_LIMIT,
            dateFrom: format(
              startOfMonth(activeDate),
              config.OUTPUT_DATE_FORMAT,
            ),
            dateTo: format(endOfMonth(activeDate), config.OUTPUT_DATE_FORMAT),
          });
          const { result, entities } = normalizeEvents([
            ...items,
            ...res.events,
          ]);

          updateEvents(entities);
          return {
            results: result,
            total: res.total,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  };

  React.useEffect(() => {
    setOffset(0);
    fetchEvents();
  }, [activeDate]);

  React.useEffect(() => {
    fetchEvents(events as any);
  }, [offset]);

  const eventsByLocation = events.reduce((acc, e) => {
    if (!e.sportGround.geo) {
      return acc;
    }
    const index = e.sportGround.geo.coordinates.toReversed().join('-');
    return { ...acc, [index]: [...(acc[index] || []), e] };
  }, {});

  let markers = [];
  let featureGroup;
  let bounds;

  if (Leaflet && Leaflet.LeafletMarker) {
    markers = Object.keys(eventsByLocation).map((idx) => {
      return new Leaflet.LeafletMarker(
        eventsByLocation[idx][0].sportGround.geo.coordinates.toReversed(),
      );
    });

    featureGroup = new Leaflet.FeatureGroup(markers as any);
    bounds = featureGroup.getBounds();
  }

  return (
    <EntryAnimation key={'home'}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="section" />
      </Helmet>
      <Page>
        {modalOpened && (
          <Modal isOpen={modalOpened} handleClose={toggleModal}>
            <ModalContent>
              <RedirectToRegistration handleClose={toggleModal} />
            </ModalContent>
          </Modal>
        )}
        <Wrapper>
          <IntroWrapper>
            <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
              <Intro>
                <Banner>
                  <img
                    alt={__('Úvodný obrázok')}
                    src="/main-image@1x.png"
                    srcSet="main-image@1x.png 1x, main-image@2x.png 2x"
                  />
                  <BannerContent>
                    <Header>{__('Dajme spolu gól')}</Header>
                    <BannerDescription>
                      <p>
                        <b>
                          {__(
                            'Futbalové aktivity detí v materských a základných školách',
                          )}
                        </b>
                      </p>
                      <p>
                        {__('Slovenský futbalový zväz… "Robíme to pre deti"')}
                      </p>
                    </BannerDescription>
                    <BannerActions>
                      <Link to="/ziadost">
                        <RegisterSchool>
                          <span>{SchoolIcon()}</span>
                          <span>{__('Registrovať školu')}</span>
                        </RegisterSchool>
                      </Link>
                      <RegisterChild onClick={toggleModal}>
                        <span>{ChildIcon()}</span>
                        <span>{__('Registrovať dieťa')}</span>
                      </RegisterChild>
                    </BannerActions>
                    <BannerDescription>
                      <p>
                        {__('Chcete vedieť viac o projekte?')}{' '}
                        <a href="https://www.futbalsfz.sk/mladez-a-rozvoj/grassroots/dajmespolugol">
                          {__('Kliknite sem!')}
                        </a>
                      </p>
                    </BannerDescription>
                  </BannerContent>
                </Banner>
              </Intro>
            </MaxWidthBox>
            <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
              <StatsWrapper>
                <Link to="/skoly">
                  <Stat>
                    <StatCount>
                      {statsIsLoading.includes('S') ||
                      statsIsLoading.includes('O') ? (
                        <Dots>
                          &nbsp;
                          <Dot />
                          <Dot />
                          <Dot />
                          &nbsp;
                        </Dots>
                      ) : (
                        organizations.length
                      )}
                    </StatCount>
                    <StatDesc>{__('zapojených škôl')}</StatDesc>
                  </Stat>
                </Link>
                <Link to="/skoly">
                  <Stat>
                    <StatCount>
                      {statsIsLoading.includes('S') ||
                      statsIsLoading.includes('E') ? (
                        <Dots>
                          &nbsp;
                          <Dot />
                          <Dot />
                          <Dot />
                          &nbsp;
                        </Dots>
                      ) : (
                        eventsStats
                      )}
                    </StatCount>
                    <StatDesc>{__('zrealizovaných aktivít')}</StatDesc>
                  </Stat>
                </Link>
              </StatsWrapper>
            </MaxWidthBox>
            <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
              <Ad>
                <AdInfo>
                  <AdImage src="/banner-img.svg" />
                  <AdContent>
                    <AdHeader>
                      {__('Online školenie trénerov - SFZ Grassroots Leader')}
                    </AdHeader>
                    <AdText>
                      {__('Odštartuj svoju trénerskú cestu aj ty')}
                    </AdText>
                  </AdContent>
                </AdInfo>
                <a
                  href="https://futbalsfz.sk/treneri/skolenia/sfz-grassroots-leader/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AdCTA>
                    <span>{__('Viac informácií')}</span>
                  </AdCTA>
                </a>
              </Ad>
            </MaxWidthBox>
            <MapHeader>
              <Header>{__('Mapa aktivít')}</Header>
            </MapHeader>
            <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
              <Calendar>
                <YearWrapper>
                  <Year
                    disabled={Number(displayedYear) - 1 <= 2017}
                    onClick={() => {
                      const newDate = setYear(
                        activeDate,
                        Number(displayedYear) - 1,
                      );
                      setDisplayedYear(format(newDate, 'yyyy'));
                    }}
                  >
                    <span>
                      {ChevronLeftIcon(
                        8,
                        12,
                        Number(displayedYear) - 1 <= 2017 ? '#C2C2C2' : '#000',
                      )}
                    </span>
                    <span>{String(Number(displayedYear) - 1)}</span>
                  </Year>
                  <Year>{displayedYear}</Year>
                  <Year
                    disabled={Number(displayedYear) + 1 > getYear(new Date())}
                    onClick={() => {
                      if (Number(displayedYear) + 1 <= getYear(new Date())) {
                        const newDate = setYear(
                          activeDate,
                          Number(displayedYear) + 1,
                        );
                        setDisplayedYear(format(newDate, 'yyyy'));
                      }
                    }}
                  >
                    <span>{String(Number(displayedYear) + 1)}</span>
                    <span>
                      {ChevronRightIcon(
                        8,
                        12,
                        Number(displayedYear) + 1 <= getYear(new Date())
                          ? '#000'
                          : '#C2C2C2',
                      )}
                    </span>
                  </Year>
                </YearWrapper>
                <Months>
                  {Object.keys(months).map((m, idx) => {
                    const count =
                      eventsCount[
                        format(
                          setYear(
                            setMonth(new Date(activeDate), idx),
                            Number(displayedYear),
                          ),
                          'yyyy-MM',
                        )
                      ] || 0;
                    return (
                      <Month
                        key={m}
                        disabled={!count}
                        onClick={() => {
                          if (count) {
                            let newDate = setMonth(activeDate, idx);
                            newDate = setYear(newDate, Number(displayedYear));
                            newDate = setDate(newDate, 1);
                            setParameter({
                              eventsDate: format(newDate, 'yyyy-MM-dd'),
                            });
                          }
                        }}
                        active={
                          isSameMonth(
                            setMonth(new Date(activeDate), idx),
                            activeDate,
                          ) &&
                          isSameYear(
                            setMonth(new Date(activeDate), idx),
                            setYear(
                              new Date(activeDate),
                              Number(displayedYear),
                            ),
                          )
                        }
                      >
                        <div>
                          {isSameMonth(
                            setMonth(new Date(activeDate), idx),
                            activeDate,
                          ) &&
                          isSameYear(
                            setMonth(new Date(activeDate), idx),
                            setYear(
                              new Date(activeDate),
                              Number(displayedYear),
                            ),
                          ) &&
                          isFetching ? (
                            <Loader size="s" />
                          ) : (
                            m
                          )}
                        </div>
                        {count > 0 && <EventCount>{count}</EventCount>}
                      </Month>
                    );
                  })}
                </Months>
              </Calendar>
              <MapWrapper>
                {!!Leaflet && (
                  <Leaflet.Map
                    {...(markers.length && { bounds })}
                    style={{
                      height: rem(462),
                      width: '100%',
                      borderRadius: rem(12),
                    }}
                    center={[48.669, 20.699]}
                    zoom={8}
                  >
                    <Leaflet.TileLayer
                      url={`https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      tileSize={512}
                      zoomOffset={-1}
                    />
                    {Object.keys(eventsByLocation).map((idx) => {
                      const markerKey = eventsByLocation[
                        idx
                      ][0].sportGround.geo.coordinates
                        .toReversed()
                        .join(',');
                      return (
                        <Leaflet.Marker
                          onClick={() => {
                            setParameter({
                              selectedMarker: markerKey,
                            });
                          }}
                          key={markerKey}
                          ref={(m: any) => {
                            if (
                              query.selectedMarker &&
                              query.selectedMarker === markerKey &&
                              m
                            ) {
                              m.leafletElement.openPopup();
                            }
                          }}
                          position={eventsByLocation[
                            idx
                          ][0].sportGround.geo.coordinates.toReversed()}
                        >
                          <Leaflet.Popup minWidth={250}>
                            <Events>
                              <EventSportGround>
                                {eventsByLocation[idx][0].sportGround.name}
                              </EventSportGround>
                              {eventsByLocation[idx].map((e: any) => (
                                <Link
                                  key={e._id}
                                  to={`/skola/${e.appSpace}/aktivita/${e._id}`}
                                >
                                  <EventWrapper>
                                    <div>
                                      <EventName>{e.name}</EventName>
                                      <EventDuration>{`${format(
                                        new Date(e.startDate),
                                        config.DATE_TIME_FORMAT,
                                      )} - ${format(
                                        new Date(e.endDate),
                                        isSameDay(e.startDate, e.endDate)
                                          ? 'HH:mm'
                                          : config.DATE_TIME_FORMAT,
                                      )}`}</EventDuration>
                                    </div>
                                    <div>
                                      <Icon name="arrow-right" />
                                    </div>
                                  </EventWrapper>
                                </Link>
                              ))}
                            </Events>
                          </Leaflet.Popup>
                        </Leaflet.Marker>
                      );
                    })}
                  </Leaflet.Map>
                )}
              </MapWrapper>
              <ActivitiesHeader>
                <Header>{__('Zrealizované aktivity v školách')}</Header>
                <div>
                  <span>{__('Hľadáte svoju školu?')}</span>
                  &nbsp;
                  <a href="/skoly">{__('Kliknite sem!')}</a>
                </div>
              </ActivitiesHeader>
              <Row>
                {[...events].map((e) => {
                  let imgSrc = '';
                  if (e.description && (e.description.widgets || []).length) {
                    const imgWidget = e.description.widgets.find(
                      (w: any) => w.type === 'photo',
                    );
                    if (imgWidget && imgWidget.picture) {
                      imgSrc = imgWidget.picture.media_url.replace(
                        '{size}',
                        '480x0xresize',
                      );
                    } else if (imgWidget && imgWidget.uri) {
                      imgSrc = `https://mediamanager.sportnet.online/images/230x172/${imgWidget.uri}`;
                    }
                  }
                  return (
                    <StyledCol key={e._id} s={6} l={4}>
                      <Link
                        key={e._id}
                        to={`/skola/${e.appSpace}/aktivita/${e._id}`}
                      >
                        <EventPreview
                          imgSrc={imgSrc}
                          title={`${e.name}`}
                          sportGroundName={e.sportGround.name}
                          date={`${format(
                            new Date(e.startDate),
                            config.DATE_TIME_FORMAT,
                          )} - ${format(
                            new Date(e.endDate),
                            isSameDay(e.startDate, e.endDate)
                              ? 'HH:mm'
                              : config.DATE_TIME_FORMAT,
                          )}`}
                        />
                      </Link>
                    </StyledCol>
                  );
                })}
              </Row>
              {(total || 0) > events.length && (
                <LoadMore>
                  <LoadMoreButton
                    onClick={() => {
                      setOffset(events.length);
                    }}
                  >
                    <LoadMoreContent $isLoading={!!isFetching}>
                      {__('Načítať ďalšie')}
                    </LoadMoreContent>
                    <StyledLoader
                      size="xl"
                      loading={!!isFetching}
                      color={'#fff'}
                    />
                  </LoadMoreButton>
                </LoadMore>
              )}
            </MaxWidthBox>
          </IntroWrapper>
        </Wrapper>
      </Page>
    </EntryAnimation>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home);
